import React, { useRef, useEffect } from "react";
import { InView } from "react-intersection-observer";

const VideoCornersBorder = ({ data, index, mobile }) => {

    const videoRef = useRef();
    const fullscreenVideoRef = useRef(null);


    useEffect(() => {
        const videoContainer = document.getElementById("video-container" + index);
        const playButton = document.getElementById("play-button" + index);

        videoContainer.addEventListener("mousemove", function (event) {
            const containerRect = videoContainer.getBoundingClientRect();
            const mouseX = event.clientX - containerRect.left;
            const mouseY = event.clientY - containerRect.top;

            const buttonWidth = playButton.offsetWidth;
            const buttonHeight = playButton.offsetHeight;
            const buttonX = mouseX - buttonWidth / 2;
            const buttonY = mouseY - buttonHeight / 2;

            const maxButtonX = containerRect.width - buttonWidth;
            const maxButtonY = containerRect.height - buttonHeight;
            playButton.style.left = Math.min(Math.max(buttonX, 0), maxButtonX) + "px";
            playButton.style.top = Math.min(Math.max(buttonY, 0), maxButtonY) + "px";
        });

        videoContainer.addEventListener("mouseleave", function () {
            setTimeout(function () {
                playButton.style.left = "50%";
                playButton.style.top = "50%";
                playButton.style.transform = "translate(-50%, -50%) scale(1)";
                playButton.style.transition = "all 0.3s ease-out";
            }, 50);
        });

        videoContainer.addEventListener("mouseover", function () {
            playButton.style.transition = "transform ease-out 0.3s";
            playButton.style.transform = "scale(1.5)";
        });


        videoContainer.addEventListener("mouseenter", function () {
            // if (!video.paused) {
            playButton.style.opacity = "1";
            //  }
        })

    }, []);

    function PlayAndPause(inView) {
        if (typeof document !== 'undefined') {
            document.getElementById('elementID' + index).click();
            if (inView) {
                if (videoRef.current.paused) {
                    videoRef.current.play().catch(err => {
                        console.error("Erro:", err);
                    });
                }
            } else {
                videoRef.current.pause();
            }
        }

    }

    useEffect(() => {
        fullscreenVideoRef.current.style.display = 'none';
    }, []);

    function openFullscreen(e) {
        e.stopPropagation()
        e.preventDefault();

        const fullscreenVideo = fullscreenVideoRef.current;
        fullscreenVideoRef.current.style.display = 'block';
        if (fullscreenVideo.requestFullscreen) {
            fullscreenVideo.requestFullscreen();
        } else if (fullscreenVideo.webkitRequestFullscreen) {
            fullscreenVideo.webkitRequestFullscreen();
        } else if (fullscreenVideo.msRequestFullscreen) {
            fullscreenVideo.msRequestFullscreen();
        }

        fullscreenVideo.addEventListener('fullscreenchange', exitFullscreen);
    }

    const exitFullscreen = () => {
        if (!document.fullscreenElement) {
            fullscreenVideoRef.current.style.display = 'none'; // Hide on exiting fullscreen
        }
    }

    return (
        <div className="container-main-destaques" >
            <InView as="div" threshold={0.7} onChange={inView => { (PlayAndPause(inView)) }}
                id={"elementID" + index} className="container-video-corners-border ">

                <div id={"video-container" + index} onClick={(e) => (openFullscreen(e))} className="video-container">
                    <video ref={videoRef} muted="muted" id={"video" + index} loop className="video">
                        <source src={mobile ? data.multimediaMobile : data.multimedia} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <button id={"play-button" + index} type="button" className="play-button">
                        <svg xmlns="http://www.w3.org/2000/svg" id="Camada_1" width="100" height="101.28" viewBox="0 0 100 101.28">
                            <path className="cls-1" fill="#fff" strokeWidth="0"
                                d="M50,9c-22.63,0-41,18.37-41,41s18.37,41,41,41,41-18.37,41-41S72.63,9,50,9ZM50,11c21.55,0,39,17.45,39,39s-17.45,39-39,39S11,71.55,11,50,28.45,11,50,11ZM39.38,29.97c-.94.02-1.87.28-2.66.75-1.57.93-2.67,2.68-2.72,4.78v28.91c.05,2.1,1.15,3.84,2.72,4.78,1.57.94,3.65,1.07,5.5.06l25-14.5c1.65-.96,2.78-2.75,2.78-4.81s-1.13-3.82-2.78-4.78c-8.23-4.99-16.8-9.58-25-14.5-.92-.51-1.96-.7-2.84-.69ZM39.5,31.94c.55.01,1.1.19,1.75.5l24.97,14.44c1.07.62,1.78,1.73,1.78,3.06s-.71,2.47-1.78,3.09c-8.31,4.67-16.02,9.33-25,14.47-1.25.66-2.48.56-3.47-.03-.99-.59-1.71-1.67-1.75-3.09v-28.84c.03-1.44.75-2.5,1.75-3.09.66-.35,1.2-.51,1.75-.5h0Z" /></svg>
                    </button>

                </div>
                {(data.title && data.subtilte) &&
                    <div className="container-margin container-text">
                        <h4 className="fs-4 ILightBeta white">{data.subtitle}</h4>
                        <h1 className="fs-1 ILightBeta white">{data.title}</h1>
                    </div>
                }
            </InView >

            <video controls id={"myvideo" + index} ref={fullscreenVideoRef} className="myvideo">
                <source src={data.fullVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div >
    );

}
export default VideoCornersBorder;