import React, { useState } from "react";
import { InView } from "react-intersection-observer";
import { GatsbyImage } from "gatsby-plugin-image"
import { getPublicPathGatsbyImage } from '../../../custom/functions'

const ZoomOut = ({ data, publicsUrl, mobile }) => {

    var [isIntersecting, setIntersecting] = useState(false);

    return (
        <div className="container-main-destaques">
            <InView as="div" threshold={0.8} onChange={inView => { setIntersecting(inView) }} className="container-zoom-out">
                <GatsbyImage
                    image={mobile ? getPublicPathGatsbyImage(publicsUrl, data.imageMobile) : getPublicPathGatsbyImage(publicsUrl, data.image)}
                    alt={data.title}
                    style={{
                        transform: isIntersecting ? "scale(1)" : "scale(1.15)",
                        transition: "all 2.5s cubic-bezier(0.17, 0.55, 0.55, 1)"
                    }}
                />
                <div className="container-margin container-text">
                    <h4 className="fs-4 ILightBeta white">{data.subtitle}</h4>
                    <h1 className="fs-1 ILightBeta white">{data.title}</h1>
                </div>
            </InView>
        </div>
    );

}
export default ZoomOut;