import * as React from "react";

import ZoomOut from "./destaques/zoomOut";
import VideoFullWidth from "./destaques/videoFullWidth";
import VideoCornersBorder from "./destaques/videoCornersBorder";

const ProjetosDestaques = ({ data, publicsUrl, index, mobile }) => {

    switch (data.block) {
        case "zoom-out":
            return (
                <ZoomOut data={data} publicsUrl={publicsUrl}  mobile={mobile} />
            );
        case "video-full-width":
            return (
                <VideoFullWidth data={data}  mobile={mobile} />
            );
        case "video-corners-border":
            return (
                <VideoCornersBorder data={data} index={index} mobile={mobile} />
            );
        default:
            return null
    }

}
export default ProjetosDestaques;